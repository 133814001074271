<template>
	<div style="position: relative">
		<Spin fix v-if="loading"></Spin>
		<div class="project-process">
			<div class="project-process-header clearfix">
				<h2>项目进度</h2>
				<div class="project-process-header__info">
					<span class="label">项目进度颜色说明：</span>
					已完成：
					<span class="end status"></span>
					进行中：
					<span class="processing status"></span>
					未开始：
					<span class="notstart status"></span>
				</div>
			</div>
			<div
				class="project-process-body clearfix"
				v-if="processList && processList.length"
			>
				<div
					:class="[processStatus.START, 'process']"
					style="width: calc(25% + 37px)"
				>
					{{ getTxt("START") }}阶段
				</div>
				<div
					:class="[processStatus.ING, 'process']"
					style="width: calc(25% + 37px)"
				>
					{{ getTxt("ING") }}阶段
				</div>
				<div
					:class="[processStatus.OTHER, 'process']"
					style="width: calc(25% + 37px)"
				>
					{{ getTxt("OTHER") }}阶段
				</div>
				<div
					:class="[processStatus.END, 'process']"
					style="width: calc(25% + 37px)"
				>
					{{ getTxt("END") }}阶段
				</div>
			</div>
		</div>
		<div class="project-detail">
			<h2 class="project-detail-title">项目详情</h2>
			<design-template-view
				:componentList="componentList"
				:projectInfo="projectInfo"
				ref="designTemplate_add_project"
			></design-template-view>
			<h2 class="project-detail-title" v-if="isOpenHgc && !isXinhua">
				遗传办信息
			</h2>
			<design-template-view
				v-if="isOpenHgc && !isXinhua"
				:componentList="hgcComponentList"
				ref="designTemplate_view_genetic"
			></design-template-view>
			<template v-if="isShowFollowCheck">
				<h2 class="project-detail-title">跟踪审查</h2>
				<Form label-postion="top" :model="checkData" class="design-form-view">
					<Row :gutter="32">
						<Col :span="8">
							<div class="form-item-wrap">
								<FormItem label="伦理批件签发日期">
									{{ followCheckData.componentValue || "--" }}
								</FormItem>
							</div>
						</Col>
						<Col :span="8">
							<div class="form-item-wrap">
								<FormItem label="伦理批件有效期">
									{{ getNotifyTypeName(followCheckData.notifyType) }}
								</FormItem>
							</div>
						</Col>
						<Col :span="8">
							<div class="form-item-wrap">
								<FormItem label="短信提醒日期">
									<span :style="`color: ${color}`">{{
										getMessageTime(
											followCheckData.componentValue,
											followCheckData.notifyType
										) || "--"
									}}</span>
								</FormItem>
							</div>
						</Col>
					</Row>
				</Form>
			</template>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
import { getEnumerateByKey } from "@/utils/util"
import designTemplateView from "@/components/BusinessComponents/FormDesign/designTemplateView.vue"
import api from "@/api/project/project"
import moment from "moment"

const { apiGet } = api
export default {
	name: "Info",
	components: {
		designTemplateView
	},
	data() {
		return {
			componentList: [],
			hgcComponentList: [],
			extraList: [],
			studyFieldList: [],
			orgId: this.$store.state.user.info.orgId
				? this.$store.state.user.info.orgId
				: "",
			isOpenHgc: 0, // 是否开启了遗传办 1 是， 0 否
			isNeedFillHgc: 0, // 是否需要完善遗传办信息  1 是， 0 否
			geneticVisible: false,
			projectId: "",
			projectType: "",
			loading: false,
			checkData: {},
			color: "#666",
			nowDate: ""
		}
	},
	computed: {
		...mapState("project", {
			processStatus: state => state.processStatus,
			projectInfo(state) {
				const projectInfo = Object.assign({}, state.projectInfo)
				projectInfo.siteCatgName = getEnumerateByKey(
					this.$store.state.enumerate.SITE_CATEGORY,
					projectInfo.siteCatg
				).v
				projectInfo.isLeaderName = getEnumerateByKey(
					this.$store.state.enumerate.LEADER_STYLE,
					projectInfo.isLeader
				).v
				projectInfo.extraData =
					projectInfo.extraData && JSON.parse(projectInfo.extraData)
				return projectInfo
			},
			followCheckData: state => state.followCheckData
		}),
		...mapState("permission", ["systemActionPermissions"]),
		...mapState("user", ["processList"]), // 流程信息
		isXinhua() {
			return this.$store.state.environment === "xinhua"
		},
		isShowFollowCheck() {
			return this.followCheckData.notifyType
		}
	},
	created() {
		this.initProject()
	},
	methods: {
		initProject() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGet({
					id: this.$route.params.projectId,
					isDealAcronym: 1
				})
				if (res && res.data && res.data.project) {
					if (res.data.project.jsonData) {
						this.componentList = JSON.parse(res.data.project.jsonData)
					}
					if (res.data.project.jsonDataAcronym) {
						const jsonDataAcronym = JSON.parse(res.data.project.jsonDataAcronym)
						this.componentList = [...this.componentList, ...jsonDataAcronym]
					}
					this.isOpenHgc = res.data.project.isOpenHgc
					this.isNeedFillHgc = res.data.isNeedFillHgc
					this.projectId = res.data.project.id
					this.projectType = res.data.project.projectConfigId
					if (res.data.project.jsonDataHgc) {
						this.hgcComponentList = JSON.parse(res.data.project.jsonDataHgc)
					}
				}
				this.loading = false
			})
		},
		getTxt(type) {
			const itemC = this.processList.find(item => item.obj.nickname === type)
			return itemC ? itemC.name : ""
		},
		getNotifyTypeName(type) {
			let name = "--"
			switch (type) {
				case 1:
					name = "3个月"
					break
				case 2:
					name = "6个月"
					break
				case 3:
					name = "12个月"
					break
				case 100:
					name = "其他"
					break
				default:
					name = "--"
					break
			}
			return name
		},
		// 获取短信通知日期
		getMessageTime(checkTime, month) {
			let date = ""
			let newMonth = month
			if (month === 100) {
				// 其他 月份
				newMonth = this.followCheckData.notifyFrequency || 0
			} else if (month === 1) {
				// 3个月/次
				newMonth = 3
			} else if (month === 2) {
				// 6个月/次
				newMonth = 6
			} else if (month === 3) {
				// 1年/次
				newMonth = 12
			}
			if (checkTime && newMonth) {
				let arr = checkTime.split("-")
				// 月份是0-11，需要减1来计算。
				arr = arr.map(item => Number(item.trim()))
				if (arr.length === 3 && arr[2] !== 0) {
					const arrDate = new Date(arr.join("-"))
					// 短信通知日期 需要提前一个月通知，要减掉1；
					const timeD = moment(arrDate)
						.add(newMonth - 1, "months")
						.format("YYYY-MM-DD")
					const timeE = moment(arrDate)
						.add(newMonth - 1, "months")
						.format("YYYY-MM-DD 10:00:00")
					const messageTime = new Date(timeE).getTime() // 短信通知日期 时间戳
					// 伦理批件有效期
					const timeEthics = moment(arrDate)
						.add(newMonth, "months")
						.format("YYYY-MM-DD 23:59:59")
					const EthicsTime = new Date(timeEthics).getTime() // 伦理批件有效期 时间戳
					// 当天日期
					this.nowDate = new Date().getTime()
					if (messageTime >= this.nowDate) {
						this.color = "#666"
						// 如果发送信息的日期在今天之后，则提示 发送日期
						date = timeD
					} else {
						// 如果发送信息的日期在今天之前
						if (EthicsTime >= this.nowDate) {
							// 如果发送信息的日期在今天之前，且在伦理批件有效期以内，提示今天
							date = "今天"
						} else {
							// 如果发送信息的日期在今天之前，且在伦理批件有效期以外，提示过期
							date = "伦理批件已过期"
						}
						this.color = "#ff5722"
					}
				}
			} else {
				date = ""
			}
			return date
		}
	}
}
</script>

<style lang="less">
@import "../../my-theme/color.less";
.project-process {
	background-color: #fff;
	padding: 16px;
	.project-process-header {
		position: relative;
		h2 {
			color: #202730;
			font-size: 18px;
			line-height: 25px;
			float: left;
		}
	}
	.project-process-header__info {
		float: right;
		text-align: right;
		font-size: 14px;
		line-height: 25px;
		.label {
			color: #999;
		}
		.status {
			margin-right: 15px;
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 2px;
			vertical-align: middle;
			&.end {
				background-color: @primary-color;
			}
			&.processing {
				background-color: #5dc0ff;
			}
			&.notstart {
				background-color: #ededed;
			}
		}
	}
	.project-process-body {
		margin-top: 15px;
		.process {
			float: left;
			height: 50px;
			border-radius: 25px;
			background-color: #ededed;
			line-height: 50px;
			margin-left: -50px;
			color: #202730;
			text-align: center;
			position: relative;
			&:nth-child(1) {
				margin-left: 0;
				z-index: 5;
			}
			&:nth-child(2) {
				z-index: 4;
			}
			&:nth-child(3) {
				z-index: 3;
			}
			&:nth-child(4) {
				z-index: 2;
			}
			&.end {
				color: #fff;
				background-color: @primary-color;
			}
			&.processing {
				color: #fff;
				background-color: #5dc0ff;
			}
		}
	}
}
.project-detail {
	background-color: #fff;
	padding: 0 16px 16px 16px;
	.project-detail-title {
		overflow: hidden;
		color: #202730;
		font-size: 18px;
		line-height: 25px;
	}
	.project-detail-body {
		margin-top: 24px;
		.label {
			font-size: 14px;
			color: #999;
		}
		.value {
			font-size: 16px;
			color: #202730;
			word-break: break-all;
		}
		.ivu-col {
			margin-bottom: 24px;
		}
	}
}
</style>
